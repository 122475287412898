import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Carousel from './Carousel'

const CarouselStaticQuery = () => (
  <StaticQuery
    query={graphql`
      query getCarouselVwImages {
        allBtswebViewtypeJson(filter: { ViewType: { eq: "Carousel" } }) {
          edges {
            node {
              Author
              Status
              ViewType
              ID
              Type
              Title
              Caption
              ApprovalStatus
            }
          }
        }
        allFile {
          edges {
            node {
              dir
              name
              extension
              publicURL
              base
              sourceInstanceName
              relativeDirectory
            }
          }
        }
      }
    `}
    render={data => (
      <Carousel
        carouselViewType={data.allBtswebViewtypeJson.edges}
        carouselImages={data.allFile.edges}
      />
    )}
  />
)

export default CarouselStaticQuery;