import React, { Component } from 'react'
import Slider from 'react-slick'
import './CarouselStyles'
import './CarouselTheme'
import styled from 'styled-components'
import { Link } from 'gatsby'

const BackImage = styled.div`
  background: ${props => `url('${props.imageUrl}')`};
  background-size: cover;
  background-position: center center;
  height: 600px;
  .carousel_text {
    text-align: center;
    color: white;
    max-width: unset;
  }

  .container {
    display: flex;
    align-content: flex-end;
    justify-content: center;
  }

  .carousel_text_wrapper {
    padding: 40px;
    background-color: rgba(120, 0, 50, 0.8);
    margin-top: 422px;
    border-radius: 4px;
    a {
      display: flex;
      justify-content: center;
      align-content: center;
      width: 10em;
      margin: 0 auto;
      background-color: transparent;
      border: 1px solid aliceblue;
    }
  }
`

class Carousel extends Component {
  carouselItems = slides =>
    slides.map((slide, index) => {
      return (
        <BackImage key={`${slide.ID}-carousel`} imageUrl={slide.publicURL}>
          <div className="carousel_text_wrapper">
            <p className="carousel_text">{slide.caption}</p>
            <Link className="usa-button" to={`${slide.link}`}>
              Learn More
            </Link>
          </div>
        </BackImage>
      )
    })

  render() {
    const settings = {
      adaptiveHeight: true,
      infinite: true,
      autoplay: true,
      speed: 4600,
      accessibility: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: '100px',
      arrows: false,
    }

    // Filter our images only for "ViewType": "Carousel"
    let carouselItems = this.props.carouselImages
      .filter(item => {
        const newCars = this.props.carouselViewType.filter(
          x => x.node.Title === item.node.base
        )
        if (
          this.props.carouselViewType.find(
            x => x.node.Title === item.node.base
          ) !== undefined &&
          newCars[0].node.Status !== 'Archived'
        ) {
          return item
        }
        return null
      })
      // combine meta data into one object
      .map(carouselImage => {
        const nodeMeta = carouselImage.node
        const imageMeta = this.props.carouselViewType[
          this.props.carouselViewType
            .map(el => el.node.Title)
            .indexOf(carouselImage.node.base)
        ]

        nodeMeta['title'] = imageMeta.node.Title
        nodeMeta['caption'] = imageMeta.node.Caption
        nodeMeta['link'] = imageMeta.node.ApprovalStatus

        return nodeMeta
      })

    return <Slider {...settings}>{this.carouselItems(carouselItems)}</Slider>
  }
}

export default Carousel
