import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Link } from 'gatsby'

const PageWrapper = styled.div`
  padding: 48px 0;
  .usa-width-one-third {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr 60px;
    grid-gap: 24px;
    background-color: whitesmoke;
    padding: 0 24px;
  }
  .column_title {
    text-align: center;
    grid-row: 1;
    font-size: 2rem;
    margin-top: 24px !important;
  }
  .column_content {
    grid-row: 2;
    min-height: 640px;
    background-color: #e4e2e0;
    border-radius: 20px;
    padding: 20px;
  }
  .usa-button {
    grid-row: 3;
    width: 100%;
  }
  /* Tablet styles */
  @media only screen and (max-width: 768px) {
    .usa-width-one-third {
      padding: 0;
    }
    .column_title {
      font-size: 1.7rem;
    }
    .column_content {
      padding: 10px;
    }
  }
`

const Page = () => (
  <PageWrapper>
    <div className="usa-grid">
      <div className="usa-width-one-third">
        <h2 className="column_title">Mission | Vision</h2>
        <div className="column_content">
          <h3>Mission</h3>
          <p>
            To revolutionize the current culture to build an integrated ready,
            agile, engaged and enduring professional team serving Army Medicine.
          </p>
          <h3>Vision</h3>
          <p>
            The AMEDD Civilian Corps of 2028 will be a ready, agile and engaged
            team of professionals recognized for quality, innovation, and
            customer service to support Army Medicine.
          </p>
        </div>
        <button
          className="usa-button"
          onClick={() => {
            navigate('/corps-chief-communications')
          }}
          style={{ marginBottom: '7px' }}
        >
          Corps Chief Communications
        </button>
      </div>
      <div className="usa-width-one-third">
        <h2 className="column_title">Army Civilian Corps Creed</h2>
        <div className="column_content">
          <ul>
            <li>I am an Army Civilian - a member of the Army Team</li>
            <li>I am dedicated to our Army, our Soldiers and Civilians</li>
            <li>I will always support the mission</li>
            <li>
              I provide leadership, stability and continuity during war and
              peace
            </li>
            <li>
              I support and defend the Constitution of the United States and
              consider it an honor to serve our Nation and our Army
            </li>
            <li>
              I live the Army values of Loyalty, Duty, Respect, Selfless
              Service, Honor, Integrity, and Personal Courage
            </li>
            <li>I am an Army Civilian</li>
          </ul>
        </div>
        <Link
          className="usa-button"
          to="/supervisor-corner"
          style={{ marginBottom: '7px' }}
        >
          Supervisor's Corner
        </Link>
      </div>
      <div className="usa-width-one-third">
        <h2 className="column_title">Army Profession</h2>
        <div className="column_content">
          <p>
            Our Profession, built on an ethical and moral foundation of our Army
            Values of Loyalty, Duty, Respect, Selfless Service, Honor,
            Integrity, and Personal Courage. Our Army Profession has 5 essential
            characteristics:{' '}
            <em>
              Trust, Honorable Service, Military Expertise, Esprit de Corps, and
              Stewardship of the Profession
            </em>
            .
          </p>
          <p>
            During FY 2014, Army launched the America's Army - Our Profession
            "Stand Strong" program so that Army professionals "Stand Strong" to
            conduct ourselves and hold each other accountable in a manner
            consistent with the Army Ethic and worthy of our professional
            status. You can learn more about the Army Civilian Corps as a
            component of the Army Profession and download some marketing
            material from the Center for Army Ethic and Profession (CAPE)
            website at <a href="http://cape.army.mil/">http://cape.army.mil/</a>
          </p>
        </div>
        <Link
          to="/wolf-pack-award"
          className="usa-button"
          style={{ marginBottom: '7px' }}
        >
          Wolf Pack Awards
        </Link>
      </div>
    </div>
  </PageWrapper>
)

export default Page
