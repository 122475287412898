import React from 'react'
import Page from './Page'
import CarouselStaticQuery from './CarouselStaticQuery'

const Homepage = () => (
  <div>
    <CarouselStaticQuery />
    <Page />
  </div>
)

export default Homepage
